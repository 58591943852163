<template>
    <b-row align-h="center">
        <b-col><h1>404</h1></b-col>
    </b-row>
</template>

<script>
export default {
    name: 'NotFound'
};
</script>

<style scoped></style>
